.contact{
    height:100vh;
    width: 60%;
    margin: 0 20%;
    padding-top: 15vh;
    box-sizing: border-box;

    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;

    z-index: 1000;

    h1,p{
        margin:0;
    }

    h1{
        align-self: flex-start;
    }

    p{
        font-size: 2rem;
    }

    .contactContainer{
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        gap: 50px;

        margin-top: 100px;
        

        svg{
            height: 20px;
            width: 20px;
            margin-right: 20px;
        }
        a{
            text-decoration: none;
            color: $text-color;
            font-size: 30px;
            font-weight: 200;
        }

        .contactElement{
            display:flex;
            flex-direction:row;
            justify-content:align;
            align-items:align;
        }

    }
}