.loader{
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    svg{
        height: 30%;
        fill: white;
    }
}