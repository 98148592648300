.intropage{
    height:80vh;
    width: 60%;
    margin: 0 20%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

    h1,p{
        margin:0;
    }

    p{
        font-size: 2rem;
    }
}
