.homepageWrapper{
    position: relative;
}

.homepage{
    width: 90%;
    min-height: 100vh;
    
    h1,p{
        margin: 0;
        color: white;
    }
}

.landingpage{
    margin: 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    
    h1{
        padding-top:30vh;
        font-weight: 400;
        font-size: 3rem;
    }
}